import React from 'react';
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  FormDataConsumer,
  SimpleForm,
  TextInput,
  DateInput,
  Filter,
  ReferenceInput,
  SelectInput,
  SimpleList,
  NumberInput,
  PasswordInput,
  required,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import moment from 'moment';

const today = new Date();

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const UserList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <List filters={<UserFilter />} {...props}>
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.name}
          secondaryText={(record) => record.username}
          tertiaryText={(record) => new Date(record.updatedAt).toLocaleDateString()}
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="name" />
          <TextField source="username" />
          <TextField source="imei" label="IMEI" />
          <TextField source="role" />
          <DateField source="activationDate" />
          <ReferenceField source="createdBy" reference="users">
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <TextField source="expiresIn" sortBy="activationDate" />
        </Datagrid>
      )}
    </List>
  );
};

export const UserEdit = ({ permissions: role, ...props }) => (
  <Edit {...props}>
    <SimpleForm redirect="list">
      <TextInput source="id" disabled />
      <ReferenceInput source="role" reference="roles" filter={{ can: role }}>
        <SelectInput optionText="assign" optionValue="id" />
      </ReferenceInput>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="username" validate={[required()]} />
      <PasswordInput source="pin" label="PIN" validate={[required()]} />
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.role === 'user'
                      && <TextInput source="imei" label="IMEI" {...rest} validate={[required()]} />}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.role === 'user'
                      && <DateInput source="activationDate" defaultValue={today} {...rest} validate={[required()]} />}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.role === 'user'
                      && <NumberInput source="expiryInYears" defaultValue={1} {...rest} validate={[required()]} />}
      </FormDataConsumer>
      <ReferenceInput source="createdBy" reference="users" disabled>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateInput source="createdAt" disabled />
      <DateInput source="updatedAt" disabled />
    </SimpleForm>
  </Edit>
);

export const UserCreate = ({ permissions: role, ...props }) => (
  <Create {...props}>
    <SimpleForm redirect="list">
      <ReferenceInput source="role" reference="roles" filter={{ can: role }}>
        <SelectInput optionText="assign" optionValue="id" validate={[required()]} />
      </ReferenceInput>
      <TextInput source="name" validate={[required()]} />
      <TextInput source="username" validate={[required()]} />
      <PasswordInput source="pin" label="PIN" validate={[required()]} />
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.role === 'user'
                      && <TextInput source="imei" label="IMEI" {...rest} validate={[required()]} />}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.role === 'user'
                      && <DateInput source="activationDate" defaultValue={today} {...rest} validate={[required()]} />}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData, ...rest }) => formData.role === 'user'
                      && <NumberInput source="expiryInYears" defaultValue={1} {...rest} validate={[required()]} />}
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);
