const jwt = require('jsonwebtoken');

const apiUrl = process.env.REACT_APP_API_URL;

export default {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const request = new Request(
      `${apiUrl}/authenticate`,
      {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      },
    );
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ token }) => {
        localStorage.setItem('token', token);
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem('token');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => (localStorage.getItem('token') ? Promise.resolve() : Promise.reject()),
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const token = localStorage.getItem('token');
    const decoded = jwt.decode(token);
    return decoded ? Promise.resolve(decoded.data.role) : Promise.reject();
  },
};
